import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import routes from '../App/services/routes'

function NextButton({ isDisabled }) {
  const disabledClassName = 'border-blue'
  const enabledClassName = 'border-orange bg-orange'
  const disabledTextClassName = 'text-blue'
  const enabledTextClassName = 'text-white'
  return (
    <form action={routes.schedulesAndPreferences()} method="get">
      <button
        type="submit"
        disabled={isDisabled}
        className={classnames(
          'border-2 border-solid text-center py-4 w-full mb-8 mr-2',
          { [disabledClassName]: isDisabled, [enabledClassName]: !isDisabled },
        )}
      >
        <span className={classnames(
          'font-sans uppercase font-medium',
          { [disabledTextClassName]: isDisabled, [enabledTextClassName]: !isDisabled },
        )}
        >
          Choose Date & Time
        </span>
      </button>
    </form>
  )
}

NextButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
}

export default NextButton
