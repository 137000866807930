import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSetRecoilState } from 'recoil'

import { setOptionsState } from '../recoilState'
import { centsToDollars } from '../../../services'

function Pills({
  name, detailDescription, options, checked, setReady, showPrice,
}) {
  const [selected, setSelected] = useState(checked)
  const [description, setDescription] = useState('')
  const setOption = useSetRecoilState(setOptionsState(name))

  const findOption = (id) => options.find((op) => op.id === parseInt(id, 10))
  const optionHtmlId = (op) => `${op.name}${op.id}`

  const updateSelection = (option) => {
    setOption({ name, item: option })
    setReady(name, true)
  }

  useEffect(() => {
    if (checked) {
      const option = findOption(checked)
      updateSelection(option)
      setDescription(option.description)
    } else {
      setReady(name, false)
    }
  }, [])

  const handleClick = (e) => {
    const option = findOption(e.target.value)
    setSelected(option.id)
    setDescription(option.description)
    updateSelection(option)
  }
  return (
    <>
      <h2 className="font-sans uppercase font-medium py-2 text-sm tracking-wider">
        {name}
        {showPrice ? ` - $${centsToDollars(findOption(selected)?.price || 0)}` : ''}
      </h2>
      <p className="mb-2">{detailDescription}</p>
      <div className="flex">
        {
          options.map((op) => (
            <div
              className={
                classnames(
                  'border border-solid border-orange rounded-md py-4 mr-2 font-sans text-med mb-2',
                  { 'bg-orange text-white': selected === op.id, 'text-orange': selected !== op.id },
                )
              }
              key={op.id}
            >
              <input
                className="invisible ml-[-15px]"
                type="radio"
                id={optionHtmlId(op)}
                name={optionHtmlId(op)}
                value={op.id}
                onChange={handleClick}
                checked={selected === op.id}
              />
              <label
                className="font-sans text-med p-4"
                htmlFor={optionHtmlId(op)}
              >
                {op.name}
              </label>
            </div>
          ))
        }
      </div>
      <div className="font-sans h-3 pb-4">
        {description}
      </div>
    </>
  )
}

Pills.propTypes = {
  name: PropTypes.string.isRequired,
  detailDescription: PropTypes.string,
  options: PropTypes.array.isRequired,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setReady: PropTypes.func.isRequired,
  showPrice: PropTypes.bool,
}

export default Pills
