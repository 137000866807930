import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSetRecoilState } from 'recoil'

import CrinkleIcon from 'images/icons/crinkle-icon.png'
import CrinkleIconWhite from 'images/icons/crinkle-icon-white.png'
import WavyIcon from 'images/icons/wavy-icon.png'
import WavyIconWhite from 'images/icons/wavy-icon-white.png'
import RingletIcon from 'images/icons/ringlet-icon.png'
import RingletIconWhite from 'images/icons/ringlet-icon-white.png'
import StraightIcon from 'images/icons/straight-icon.png'
import StraightIconWhite from 'images/icons/straight-icon-white.png'
import { setOptionsState } from '../recoilState'

const ICON_IMAGES = {
  Crinkle: {
    orange: CrinkleIcon,
    white: CrinkleIconWhite,
  },
  Wavy: {
    orange: WavyIcon,
    white: WavyIconWhite,
  },
  Ringlet: {
    orange: RingletIcon,
    white: RingletIconWhite,
  },
  Straight: {
    orange: StraightIcon,
    white: StraightIconWhite,
  },
}

function Icons({
  name, options, checked, setReady,
}) {
  const [selected, setSelected] = useState(checked)
  const [description, setDescription] = useState('')
  const setOption = useSetRecoilState(setOptionsState(name))

  const findOption = (id) => options.find((op) => op.id === parseInt(id, 10))
  const optionHtmlId = (op) => `${op.name}${op.id}`

  const updateSelection = (option) => {
    setOption({ name, item: option })
    setReady(name, true)
  }

  const isSelected = (id) => selected === id

  useEffect(() => {
    if (checked) {
      const option = findOption(checked)
      updateSelection(option)
      setDescription(option.description)
    } else {
      setReady(name, false)
    }
  }, [])

  const handleClick = (e) => {
    const option = findOption(e.target.value)
    setSelected(option.id)
    setDescription(option.description)
    updateSelection(option)
  }
  return (
    <>
      <h2 className="font-sans uppercase font-medium py-2 text-sm tracking-wider">{name}</h2>
      <div className="flex">
        {
          options.map((op) => (
            <div key={op.id}>
              <label
                className="font-sans text-med"
                htmlFor={optionHtmlId(op)}
              >
                <div
                  className={
                    classnames(
                      'border border-solid border-orange rounded-md p-4 mr-2 font-sans text-med mb-2',
                      { 'bg-orange text-white': selected === op.id, 'text-orange': selected !== op.id },
                    )
                  }
                >
                  <img
                    className="h-[28px] w-[28px]"
                    src={isSelected(op.id) ? ICON_IMAGES[op.name].white : ICON_IMAGES[op.name].orange}
                    alt=""
                  />
                </div>
                <input
                  className="hidden ml-[-15px]"
                  type="radio"
                  id={optionHtmlId(op)}
                  name={optionHtmlId(op)}
                  value={op.id}
                  onChange={handleClick}
                  checked={isSelected(op.id)}
                />
              </label>
              <div
                className="font-sans text-sm font-normal"
              >
                {op.name}
              </div>
            </div>
          ))
        }
      </div>
      <div className="font-sans pt-4 h-3">
        {description}
      </div>
    </>
  )
}

Icons.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setReady: PropTypes.func.isRequired,
}

export default Icons
