import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Icons from './inputs/Icons'
import Pills from './inputs/Pills'
import Pictures from './inputs/Pictures'
import Checkbox from './inputs/Checkbox'
import CheckboxWithPictures from './inputs/CheckboxWithPictures'
import NextButton from './NextButton'
import { OPTION_TYPES } from '../../constants'

function Options({ styleDetails }) {
  const [readyStates, setReadyStates] = useState({})

  const handleReady = (name, value) => {
    setReadyStates((prevReadyStates) => ({ ...prevReadyStates, [name]: value }))
  }

  const isDisabled = () => Object.values(readyStates).includes(false)

  return (
    <>
      <div className="pt-2 pb-4">
        {
          styleDetails.map(
            (styleDetail) => (
              <div key={styleDetail.id} className="mb-2 mt-4">
                {
                  (styleDetail.displayType === OPTION_TYPES.pill
                    || styleDetail.displayType === OPTION_TYPES.detailedPill) && (
                    <Pills
                      name={styleDetail.name}
                      detailDescription={styleDetail.description}
                      options={styleDetail.options}
                      checked={styleDetail.defaultOptionId}
                      setReady={handleReady}
                      showPrice={styleDetail.displayType === OPTION_TYPES.detailedPill}
                    />
                  )
                }
                {
                  styleDetail.displayType === OPTION_TYPES.icons && (
                    <Icons
                      name={styleDetail.name}
                      options={styleDetail.options}
                      checked={styleDetail.defaultOptionId}
                      setReady={handleReady}
                    />
                  )
                }
                {
                  styleDetail.displayType === OPTION_TYPES.pictures && (
                    <Pictures
                      name={styleDetail.name}
                      options={styleDetail.options}
                      setReady={handleReady}
                    />
                  )
                }
                {
                  styleDetail.displayType === OPTION_TYPES.checkbox && (
                    <Checkbox
                      name={styleDetail.name}
                      option={styleDetail.options[0]}
                      setReady={handleReady}
                    />
                  )
                }
                {
                  styleDetail.displayType === OPTION_TYPES.checkboxWithPictures && (
                    <CheckboxWithPictures
                      name={styleDetail.name}
                      option={styleDetail.options[0]}
                      setReady={handleReady}
                    />
                  )
                }
              </div>
            ),
          )
        }
      </div>
      <NextButton isDisabled={isDisabled()} />
    </>
  )
}

Options.propTypes = {
  styleDetails: PropTypes.array.isRequired,
}

export default Options
