import { get } from './request'

const API_ROOT = '/api/v1'

export const apiRoutes = {
  bannerTexts: {
    index: () => `${API_ROOT}/banner_texts`,
  },
  styles: {
    index: (query) => {
      const baseUrl = new URL(`https://example.com${API_ROOT}/styles`)

      if (query) {
        Object.keys(query).forEach((k) => {
          baseUrl.searchParams.append(k, query[k])
        })
        return baseUrl.pathname + baseUrl.search
      }
      return baseUrl.pathname
    },
    show: (id) => `${API_ROOT}/styles/${id}`,
    galleryPhotos: {
      index: (styleId) => `${API_ROOT}/styles/${styleId}/gallery_photos`,
    },
  },
}

export const api = {
  bannerTexts: {
    index: () => get(apiRoutes.bannerTexts.index()),
  },
  styles: {
    index: (query) => get(apiRoutes.styles.index(query)),
    show: (id) => get(apiRoutes.styles.show(id)),
    galleryPhotos: {
      index: (styleId) => get(apiRoutes.styles.galleryPhotos.index(styleId)),
    },
  },
}
