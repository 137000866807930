export const OPTION_TYPES = {
  checkbox: 'checkbox',
  checkboxWithPictures: 'checkbox_with_pictures',
  detailedPill: 'detailed_pill',
  icons: 'icons',
  pictures: 'pictures',
  pill: 'pill',
}

export const ICON_TYPES = {
  crinkle: 'Crinkle',
  wavy: 'Wavy',
  ringlet: 'Ringlet',
  straight: 'Straight',
}
