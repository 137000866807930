const STYLE_KEY = 'myStyle'
const OPTIONS_KEY = 'myOptions'
const DURATION_KEY = 'myDuration'
const PRICE_KEY = 'myPrice'

const get = (key) => {
  const jsonString = window.localStorage.getItem(key)
  return JSON.parse(jsonString)
}
const set = (key, val) => {
  const jsonString = JSON.stringify(val)
  return window.localStorage.setItem(key, jsonString)
}

export const setHydrateFromLocalStore = (bool) => set('hydrate', bool)
export const getHydrateFromLocalStore = () => get('hydrate')

export const getMyOptions = () => get(OPTIONS_KEY)
const clearMyOptions = () => set(OPTIONS_KEY, {})

export const getMyOption = (key) => get(OPTIONS_KEY)[key]
export const clearMyOption = (key) => {
  const options = getMyOptions()
  const newOptions = { ...options, [key]: undefined }
  set(OPTIONS_KEY, newOptions)
}
export const setMyOption = (key, val) => {
  const options = getMyOptions()
  const newOptions = { ...options, [key]: val }
  set(OPTIONS_KEY, newOptions)
}

export const getMyStyle = () => get(STYLE_KEY)
export const setMyStyle = (style) => {
  clearMyOptions()
  set(STYLE_KEY, style)
}

// This is a code smell as it's doing much more than getting and setting
export const calcMyDuration = () => {
  const myStyleDuration = getMyStyle().duration
  let myOptionsDurations = 0
  const options = getMyOptions()
  Object
    .keys(options)
    .forEach((key) => { myOptionsDurations += options[key].duration })
  return myOptionsDurations + myStyleDuration
}

export const getMyDuration = () => get(DURATION_KEY)
export const setMyDuration = () => set(DURATION_KEY, calcMyDuration())

// This is a code smell as it's doing much more than getting and setting
export const calcMyPrice = () => {
  const myStylePrice = getMyStyle().price
  let myOptionsPrices = 0
  const options = getMyOptions()
  Object
    .keys(options)
    .forEach((key) => { myOptionsPrices += options[key].price })

  return myOptionsPrices + myStylePrice
}

export const getMyPrice = () => get(PRICE_KEY)
export const setMyPrice = () => set(PRICE_KEY, calcMyPrice())
