const routes = {
  root: () => '/',
  schedulesAndPreferences: () => {
    if (process.env.NEW_SITE) {
      return '/bookings/#/schedule-and-preferences'
    }
    return '/#/schedule-and-preferences'
  },
  styles: {
    index: () => '/styles',
    show: (id) => `/styles/${id}`,
  },
}

export default routes
