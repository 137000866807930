import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import classnames from 'classnames'
import { useSetRecoilState } from 'recoil'

import { setOptionsState } from '../recoilState'

function Pictures({ name, options, setReady }) {
  const [value, setValue] = useState(null)
  const setOption = useSetRecoilState(setOptionsState(name))

  const findOption = (id) => options.find((op) => op.id === parseInt(id, 10))

  const handleClick = (e) => {
    const option = findOption(e.target.value)
    setValue(option)
    setOption({ name, item: option })
    setReady(name, true)
  }

  useEffect(() => setReady(name, false), [])

  return (
    <div>
      <h2 className="font-sans uppercase font-medium py-1 text-sm tracking-wider pt-4">{name}</h2>
      <div className="flex flex-wrap -ml-2">
        {
          options.map((op) => (
            <div key={op.id}>
              <label
                className=""
                htmlFor={op.name}
              >
                <div className={
                  classnames(
                    'mr-2 md:mr-3 p-1 border-2 border-solid border rounded-full',
                    {
                      'border-orange': op.id === value?.id,
                      'border-transparent': op.id !== value?.id,
                    },
                  )
}
                >
                  <img
                    alt={`${op.name} example`}
                    className="rounded-full h-[60px]"
                    src={op.pictures[0]}
                  />
                </div>
                <input
                  className="hidden md:block md:invisible h-[0px]"
                  type="radio"
                  id={op.name}
                  name={name}
                  value={op.id}
                  onChange={handleClick}
                />
              </label>
            </div>
          ))
        }
      </div>
      <div className="font-sans font-medium text-sm mt-2">
        {!isEmpty(value) ? findOption(value.id)?.name : '  '}
      </div>
    </div>
  )
}

Pictures.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  setReady: PropTypes.func.isRequired,
}

export default Pictures
