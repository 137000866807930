import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAsync } from 'react-async-hook'
import { useParams } from 'react-router-dom'
import { useSetRecoilState, useRecoilValue } from 'recoil'

import { getTotalsState, styleStatsState } from './recoilState'
import { isLoading, centsToDollars } from '../../services'
import {
  setMyStyle,
  setMyDuration,
  setHydrateFromLocalStore,
  setMyPrice,
} from '../../common/localStorage'
import Options from './Options'
import Carousel from './Carousel'

function StyleDetail({ styleShow }) {
  const { styleId } = useParams()
  const [style, setStyle] = useState({})
  const [displayedPhoto, setDisplayedPhoto] = useState(null)

  const minToHours = (min) => (min / 60).toFixed(1)
  const setStyleStats = useSetRecoilState(styleStatsState)
  const totals = useRecoilValue(getTotalsState())

  const findPhoto = (id, photos) => photos.find((photo) => photo.id === id)

  const getStyle = async () => {
    const { data } = await styleShow(styleId)
    setStyle(data)
    setStyleStats({
      duration: data.duration,
      price: data.price,
    })

    const featuredPhoto = findPhoto(data.featured_photo_id, data.gallery)
    setDisplayedPhoto(featuredPhoto)

    if (data.id !== styleId) {
      // Save the attributes that schedule + preferences need
      const myStyle = {
        id: styleId,
        name: data.name,
        pictures: [featuredPhoto?.media],
        description: data.description,
        boulevard_id: data.boulevard_id,
        duration: data.duration,
        price: data.price,
      }
      setMyStyle(myStyle)
    }
  }

  const { status } = useAsync(getStyle)

  useEffect(() => {
    setHydrateFromLocalStore(true)
    if (!isLoading(status)) {
      setMyDuration()
      setMyPrice()
    }
  }, [status])

  return (
    <div>
      {
        !isLoading(status) && (
          <>
            <Carousel photos={style.gallery} onClick={setDisplayedPhoto} />
            <div className="flex flex-col sm:flex-row mt-2">
              <div key="featured" className="flex-1 mr-4 mt-1 order-last sm:order-none">
                <img
                  className="hidden sm:block"
                  src={displayedPhoto?.media}
                  alt={displayedPhoto?.accessiblityDesription}
                />
                <div className="mx-4 mt-8">
                  <div key="header" className="font-serif text-xl py-2 hidden sm:block">
                    {style.description_header}
                  </div>
                  <div key="description" className="font-sans text-sm leading-5 whitespace-pre-line font-light">
                    {style.description}
                  </div>
                  <ul key="good to know" className="font-sans text-lg pt-8 list-disc">
                    Good To Know
                    {
                      style.good_to_know.map(
                        (tip) => (
                          <li
                            key={tip}
                            className="font-sans text-sm ml-6 mt-2 font-light capitalize"
                          >
                            {tip}
                          </li>
                        ),
                      )
                    }
                  </ul>
                  <ul key="care-instructions" className="font-sans text-lg pt-8 list-disc">
                    Care Instructions
                    {
                      style?.care_instructions?.map((inst) => (
                        <li
                          key={inst.header}
                          className="font-sans text-sm font-bold py-2 ml-6 mt-2 uppercase tracking-wider"
                        >
                          {inst.header}
                          {': '}
                          <span className="font-sans text-sm leading-6 font-light normal-case">{inst.description}</span>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
              <div key="options" className="flex-1 mx-4 flex flex-col mt-1">
                <div className="font-serif text-4xl md:text-5xl mb-2">{style.name}</div>
                <div className="pb-4 md:pb-8">
                  <span className="font-serif text-lg font-medium">
                    $
                    {centsToDollars(totals.price)}
                    {' '}
                    /
                  </span>
                  <span className="font-serif text-lg font-medium">
                    {' '}
                    {minToHours(totals.duration)}
                    {' '}
                    <span className="font-sans text-sm">hours</span>
                  </span>
                </div>

                <div key="header" className="font-serif text-lg pt-2 pb-4 sm:hidden">
                  {style.description_header}
                </div>
                <div className="font-sans font-normal text-med sm:font-medium med:text-lg">
                  Customize your look
                </div>
                <Options styleDetails={style.style_details} />
              </div>
            </div>
          </>
        )
      }
    </div>
  )
}

StyleDetail.propTypes = {
  styleShow: PropTypes.func.isRequired,
}

export default StyleDetail
