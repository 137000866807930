import {
  atom, selector, atomFamily, selectorFamily,
} from 'recoil'
import { omit } from 'lodash'

import { setMyDuration, setMyPrice, setMyOption } from '../../common/localStorage'

const styleStatsState = atom({
  key: 'styleStatsState',
  default: {
    duration: 0,
    price: 0,
  },
})

const getStyleStatsState = selector({
  key: 'getStyleStatsState',
  get: ({ get }) => get(styleStatsState),
})

const optionNamesState = atom({
  key: 'optionNamesState',
  default: {},
})

// atomFamily needed to group a bunch of atoms together
const optionsState = atomFamily({
  key: 'optionsState',
  default: {},
  effects: [
    ({ onSet }) => {
      onSet((optionState) => {
        setMyOption(optionState.name, optionState.item)
        setMyDuration()
        setMyPrice()
      })
    },
  ],
})

// selectorFamily needed to access piece across different atoms whether they
// are in a family or not
const getTotalsState = selectorFamily({
  key: 'getTotalsState',
  get: () => ({ get }) => {
    const { duration: styleDuration, price: stylePrice } = get(styleStatsState)
    const names = Object.keys(get(optionNamesState))
    const totals = { duration: styleDuration, price: stylePrice }

    names.forEach((name) => {
      const optionState = get(optionsState(name))
      totals.duration += (optionState?.item?.duration || 0)
      totals.price += (optionState?.item?.price || 0)
    })

    return totals
  },
})

// Todo: move the calculation of style + duration into here w/ a get
const setOptionsState = selectorFamily({
  key: 'setOptionsState',
  set: (field) => ({ set }, newOption) => {
    set(optionNamesState, (prevState) => {
      if (newOption.item) {
        return { ...prevState, [field]: field }
      }
      return omit(prevState, field)
    })
    set(optionsState(field), newOption)
  },
})

export {
  styleStatsState,
  getStyleStatsState,
  optionsState,
  getTotalsState,
  setOptionsState,
}
