import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSetRecoilState } from 'recoil'

import { setOptionsState } from '../recoilState'
import { centsToDollars } from '../../../services'

function Checkbox({ name, option, setReady }) {
  const [value, setValue] = useState(false)
  const setOption = useSetRecoilState(setOptionsState(name))

  const handleClick = () => {
    const newValue = !value

    setValue(newValue)

    /* eslint-disable no-unused-expressions */
    newValue ? setOption({ name, item: option }) : setOption({ name, item: undefined })
    /* eslint-enable no-unused-expressions */
  }

  useEffect(() => setReady(name, true), [])

  return (
    <div>
      <div className="flex">
        <input
          type="checkbox"
          className="border-2 border-orange rounded checkbox-orange"
          name={name}
          value={option.id}
          onChange={handleClick}
        />
        <label
          htmlFor={option.name}
          className="pl-2 tracking-wider w-full"
        >
          <div
            className="font-sans uppercase font-medium pb-1 text-sm flex justify-between"
          >
            {name}
            <span className="font-serif text-med font-medium">
              +$
              {centsToDollars(option.price)}
            </span>
          </div>
          <div className="pr-8">
            {option.description}
          </div>
        </label>
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
  setReady: PropTypes.func.isRequired,
}

export default Checkbox
