import axios from 'axios'
import $ from 'jquery'

const JSON_HEADERS = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json',
}

const FORM_HEADERS = {
  'Content-Type': 'multipart/form-data',
}
let csrfToken

$(() => {
  csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute('content')
})

function request(url, method, data = {}, opts = {}) {
  const headers = opts.form ? FORM_HEADERS : {}
  return axios({
    method,
    url,
    headers: { ...JSON_HEADERS, ...headers, 'X-CSRF-Token': csrfToken },
    data,
  })
}

export const get = (url, data) => (
  axios.get(url, {
    params: data,
    headers: JSON_HEADERS,
  })
)

export const post = (url, data, opts = {}) => request(url, 'post', data, opts)

export const put = (url, data) => request(url, 'put', data)

export const patch = (url, data, opts = {}) => request(url, 'patch', data, opts)

export const deleteReq = (url) => request(url, 'delete')
