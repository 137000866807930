import React, { useRef, useEffect } from 'react'
import PropType from 'prop-types'
/* eslint-disable import/no-unresolved */
import { register } from 'swiper/element/bundle'
import 'swiper/css/thumbs'
import 'swiper/css'
/* eslint-enable import/no-unresolved */

register()

function Carousel({ photos, onClick }) {
  const swiperRef = useRef(null)

  const handleClick = (photo) => () => onClick(photo)

  useEffect(() => {
    const swiperParams = {
      slidesPerView: 4.3,
      breakpoints: {
        1: {
          slidesPerView: 2.3,
        },
        512: {
          slidesPerView: 3.3,
        },
        820: {
          slidesPerView: 4.3,
        },
        1024: {
          slidesPerView: 5.3,
        },
        1200: {
          slidesPerView: 6.3,
        },
      },
      on: {
        init() { },
      },
    }

    Object.assign(swiperRef.current, swiperParams)

    swiperRef.current.initialize()
  }, [])

  return (
    <div>
      <swiper-container
        init="false"
        navigation="true"
        ref={swiperRef}
      >
        {
          photos.map((photo) => (
            <swiper-slide
              key={photo.id}
              class="mx-0.5 h-1/2"
            >
              <button
                type="button"
                className="overflow-hidden"
                onClick={handleClick(photo)}
                onKeyUp={handleClick(photo)}
              >
                <img
                  className="hover:scale-125"
                  src={photo.mediaSmall}
                  alt={photo.accessibleDescription}
                />
              </button>
            </swiper-slide>
          ))
        }
      </swiper-container>
    </div>
  )
}

Carousel.propTypes = {
  photos: PropType.array.isRequired,
  onClick: PropType.func.isRequired,
}

export default Carousel
